import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {AboutSection, Avatar, Title, Text, SubTitle} from './style';
import {SectionIntro, ContainerLayout, ResumeButton} from "../common";

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "profile.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <SectionIntro>
        <ContainerLayout>
          <AboutSection>
            <div>
              <Avatar fluid={data.placeholderImage.childImageSharp.fluid} alt="user photo" />
              <SubTitle> Software Developer</SubTitle>
            </div> 
            <div>
              <Title> Hi, I'm Paul</Title>
              <Text> I am a Software Developer from Charleston, SC. Previously, I have worked in the financial sector in New York, doing a variety of things from DevOps/Build Engineering to most recently working with Observability systems.</Text>
              <Text> My experience ranges up and down the stack, from developing REST APIs, compiling & packaging third-party C++ libraries, deploying apps on and troubleshooting kubernetes, all the way to writing data transform pipelines in Apache Beam.</Text>
              {/* <Text> Professionally, my interests include data platform engineering, building out data pipelines on frameworks such as Apache Beam, Spark, etc, creating and using Observability systems to improve software operations, and much more; mostly in the space of  */}
                {/* using data to solve problems. */}
              {/* </Text> */}
              <Text> Outside of work I love kiteboarding, gardening, travel, and video games. This blog is mostly what I am tinkering with in my time away from work.
              </Text>
              <ResumeButton href="resume.pdf" target="_blank"> Download resume </ResumeButton>
            </div>
          </AboutSection>
        </ContainerLayout>
      </SectionIntro>
    </>
  )
}


export default About
